<script setup>
import { useSlots } from 'vue';

defineProps({
  value: String,
});

let slots = useSlots();
</script>

<template>
  <label class="block text-sm font-medium leading-6 text-gray-900 flex justify-between">
    <span v-if="value">{{ value }}</span>
    <span v-else><slot/></span>
    <span v-if="slots['action'] !== undefined">
          <slot name="action"/>
        </span>
  </label>
</template>
